import React from "react"
import { Link } from "gatsby"
import { Row, Col, Container, Breadcrumb, Image, Figure } from "react-bootstrap"

import Layout from "../../components/layout"
import Seo from "../../components/seo"

import LuteinVidMp4 from "./../../images/lutein_retina_bulb_sm.mp4"
import LuteinVidWebm from "./../../images/lutein_retina_bulb_sm.webm"
import LuteinVidThumb from "./../../images/lutein_retina_bulb_thumb.jpg";

import lutShield from "./../../images/lutein-tired-eyes.jpg"
import warningSign from "./../../images/lut-virusi-warning-sign.svg"

import lutein from "./../../images/lutein-wet-wipe-promo.jpg"
import no1 from "./../../images/lutein-no1-stamp.svg";


const LuteinKrutiMerki = () => (
  <Layout backButton={true} pageInfo={{ pageName: "kruti-merki" }}>
    <Seo title="Крути мерки срещу отслабеното зрение" />
    <Container className="p-0">
      <Row>
        <Col className="d-none d-md-block">
          <Breadcrumb>
            <li className="breadcrumb-item">
              <Link to="/">НАЧАЛНА СТРАНИЦА</Link>
            </li>
            <li className="breadcrumb-item">
              <Link to="/articles">СТАТИИ</Link>
            </li>
            <Breadcrumb.Item active>
              КРУТИ МЕРКИ СРЕЩУ ОТСЛАБЕНОТО ЗРЕНИЕ
            </Breadcrumb.Item>
          </Breadcrumb>
        </Col>
      </Row>

      <Row>
        <Col sm={12}>
          <video
            className="w-100"
            loop={true}
            autoPlay="autoplay"
            poster={LuteinVidThumb}
            muted
          >
            <source src={LuteinVidMp4} type="video/mp4" />
            <source src={LuteinVidWebm} type="video/webm" />
            This browser does not support the HTML5 video element.
          </video>
        </Col>
      </Row>

      <Row className="mb-3 product-container">
        <Col sm={12} className="mb-4">
          <h1 className="display-5 font-light mb-4 large-heading-mobile">
            Крути мерки срещу отслабеното зрение
          </h1>
          <h2 className="font-light ">
            <em>Ограничават увреждането на очите с възрастта</em>
          </h2>
        </Col>
        <Col sm={12}>
          <p>
            Да спреш да виждаш е страшно. Разбираме, че сме лишени от нормален
            живот, чак когато „завесата“ падне пред очите ни безвъзвратно. После
            ни е яд, че не сме взели мерки по-рано.
          </p>
        </Col>
      </Row>
      <Row className="mb-3 product-container">
        <Col sm={12}>
          <h4>Ще видим ли спасението?</h4>
          <p>
            Очите ни изнемогват. Годините, компютрите, замърсяването – всичко
            „работи“ в посока увреждане. Подкрепа за зрението обаче вече има.
            Спечелила не случайно титлата „експерт в очното здраве“, уникалната
            формула, родена във Франция и позната тук под името ЛУТЕИН КОМПЛЕКС
            ПЛЮС (Lutein Complex Plus), осигурява всичко нужно на очите. В три
            прости стъпки тя затяга мерките срещу отслабващото с възрастта
            зрение.
          </p>
        </Col>
      </Row>
      <Row className="mb-3 product-container">
        <Col sm={7}>
          <h4>Успешно захранване</h4>

          <p>
            Праховите форми отдавна не са на мода. Не случайно все по-често
            ползваме течни перилни препарати и течни горива. Така ефективността
            на активните вещества нараства в пъти. По същия начин съставките в
            Lutein Complex са течни за максимално бързо усвояване. Благодарение
            на патентована технология, наречена LICAPS™, Lutein Complex не
            просто дава на очите онова, което им е необходимо, а им го осигурява
            във вида, в който тялото може да се възползва по най-добрия начин –
            под формата на маслен разтвор. Към момента френската разработка е
            единствената подкрепа за очи в маслен вариант, вложен в удобни
            капсули.
          </p>
        </Col>
        <Col sm={5}>
          <Figure className="w-100 float-right rounded">
            <Figure.Image
              alt="Всичко от заобикалящия ни свят „работи“ в посока увреждане на очите."
              src={lutShield}
            />
            <Figure.Caption>
              Всичко от заобикалящия ни свят „работи“ в посока увреждане на
              очите.
            </Figure.Caption>
          </Figure>
          {/* <Image className="w-100 float-right rounded" src={lutShield} /> */}
        </Col>
      </Row>
      <Row className="mb-3 product-container">
        <Col>
          <h4>За всеки проблем - решение</h4>

          <p>
            Подсилвайки защитната бариера на очите, ЛУТЕИН КОМПЛЕКС ПЛЮС се
            оказва особено ценен в ситуации, свързани с вредни външни фактори.
            Защитени отвън, очите вече разчитат на подкрепа отвътре. Служещо
            едновременно като „щит“ и своеобразно зарядно, ЛУТЕИН КОМПЛЕКС ПЛЮС
            дава на очите ни нужната подкрепа посредством специално подбрани
            съставки. Подхранващи ретината, концентрираните количества лутеин и
            зеаксантин подобряват работата й и едновременно с това предпазват
            окото от външната среда. За да стигнат тези полезни вещества до него
            обаче, са нужни и здрави капиляри – тази важна роля се пада на
            черната боровинка, добавена като мощен екстракт. Накрая, за да
            затвърди ефекта на всички тези вещества,{" "}
            <Link to="/lutein-complex">ЛУТЕИН КОМПЛЕКС ПЛЮС</Link> дава на очите
            още бонуси. Витамините С и Е стават верни стражи в опазването на
            здравите клетки от т.н. оксидативен стрес. Добавеният цинк също
            работи в тази посока, но не само – той помага за поддържане на
            нормално зрение и компенсира щетите от постоянното излагане на
            светлина.
          </p>
        </Col>
      </Row>
      <Row className="mb-3 product-container">
        <Col>
          <h4>Само 1 на ден</h4>

          <p>
            Предлагайки така добре премислена подкрепа за очите, ЛУТЕИН КОМПЛЕКС
            ПЛЮС помага при широк спектър от смущения като например замъгляване,
            умора и напрежение, сухота, дискомфорт вследствие на прекомерно
            стоене пред компютър например. Клинично потвърдено е също така, че с
            комплексното си действие{" "}
            <Link to="/lutein-complex">ЛУТЕИН КОМПЛЕКС ПЛЮС</Link> понижава
            риска от развитието на макулна дегенерация с напредване на възрастта
            и подпомага функционалното състояние на ретината – ефект, който е
            особено ценен за хората, предразположени към образуване на катаракта
            – перде на очите. Наблюденията сочат, че само с 1 капсула на ден за
            3 месеца с негова помощ зрението отново става остро и ясно
            независимо от възрастта. Дори се подобрява виждането при
            недостатъчна светлина и през нощта
          </p>
          <Image
            width={100}
            height={100}
            className="float-right ml-4"
            src={no1}
          />
          <p>
            Така, умно поддържано с естествената сила на природата, окото
            най-сетне служи по предназначение – да вижда добре!
          </p>
        </Col>
      </Row>
      <Row className="mb-3 product-container">
        <Col>
          <p>
            <em>
              *Най-продаван продукт в категория „Витамини и добавки за очи“ за
              2020 г. по данни на IQVIA.
            </em>
          </p>
        </Col>
      </Row>
      <Row className="mb-3 product-container">
        <Col className="alert alert-warning">
          <Image
            style={{
              margin: `1rem`,
              width: `80px`,
              float: `left`,
            }}
            src={warningSign}
          />
          <h4 className="warning-text">
            <strong>Цинкът</strong> като убиец на вируси
          </h4>
          <p className="warning-text">
            Специалистите препоръчват приема на цинк заради неговия ефект върху
            имунната система и общите лечебни процеси в организма. Той спомага
            активацията на Т-помощните клетки, които отиват на лов за вирусите,
            попаднали в нашия организъм. Въпреки че огнището на болестта не може
            да бъде предотвратено, е наблюдавано, че цинкът помага симптомите
            при настинка и вирусни инфекции да отшумяват по-бързо.
          </p>
        </Col>
      </Row>
      <Row className="product-container">
        <Col md={{ span: 8, offset: 2 }}>
          <Link to="/lutein-complex">
            <Image className="w-100" src={lutein} />
          </Link>
        </Col>
      </Row>
      <Row className="product-container">
        <Col>
          <p>
            Вземете сега ЛУТЕИН КОМПЛЕКС ПЛЮС с подарък мокри кърпички за очила,
            в най-близката до вас аптека.
          </p>
          <p>
            Телефон за консултация и поръчка:{" "}
            <a href="tel:0876181000">0876 18 1000</a>
          </p>
        </Col>
      </Row>
      <Row className="product-container mb-3">
        <Col>
          <h4>
            <Link to="/lutein-complex">
              Повече информация за ЛУТЕИН КОМПЛЕКС ПЛЮС можете да намерите тук.
            </Link>
          </h4>
        </Col>
      </Row>
      <Row className="product-container mb-3">
        <Col>
          <p>
            Да не се превишава препоръчваната дневна доза! Продуктът да не се
            използва като заместител на разнообразното хранене!
          </p>
        </Col>
      </Row>
    </Container>
  </Layout>
);

export default LuteinKrutiMerki
